<template>
  <base-header>
    <template #content>
      <!--
      <div class="flex-row-start-start account-role-list">
        <div v-for="(item) in accountRoles" :key="item.accountId" class="account-role-box">
          <a-link>{{ item.accountName }}</a-link>
          <div>
            <div><span>Role</span><span>{{ item.roleName }}</span></div>
            <div><span>Account ID</span><span>{{ item.accountId }}</span></div>
          </div>
        </div>
      </div>
      -->
      <account-list></account-list>
    </template>
  </base-header>
</template>

<script>
import BaseHeader from "@/components/baseHeader";
import AccountList from "@/views/account/account-list";

export default {
  components: {
    AccountList,
    BaseHeader
  },
  data() {
    return {
      accountRoles: [
        {
          accountId: "aid_1111111111111",
          accountName: "aname_1111111111111",
          roleId: "rid_1111111111111",
          roleName: "rname_1111111111111"
        },
        {
          accountId: "aid_2222222222222",
          accountName: "aname_2222222222222",
          roleId: "rid_2222222222222",
          roleName: "rname_2222222222222"
        }
      ]
    };
  },
  methods: {
    async logout() {
      await this.$store.dispatch("user/logout");
      this.$router.push({ path: "/login" });
    }
  }
};
</script>

<style lang="scss" scoped>

.account-role-list {
  padding: 10px;
}

.account-role-box {
  padding: 10px;
  box-sizing: content-box;
  border: solid 1px #333333;
  border-radius: 8px;
}

</style>
