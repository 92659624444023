<template>
  <a-layout class="main-layout">
    <a-layout-header class="my-header">
      <div class="flex-row-start-center my-header-content">
        <slot name="header">
          <img style="width: 130px;height: 42px;margin-left: 16px;flex-grow:0;flex-shrink: 0;"
               :src="this.getAbsUrl('a4x_logo_260x84.png')" />
        </slot>
        <div style="flex-grow: 1"></div>
        <div style="flex-grow:0;flex-shrink: 0; padding:0 20px;">
          <a-dropdown>
            <!--          <a-space style="align-items: flex-end;">-->
            <a-avatar :style="{ backgroundColor: '#3370ff' }">
              <IconUser />
            </a-avatar>
            <!--          </a-space>-->
            <template #content>
              <a-doption @click="this.$router.push({path:'/person-info'})">Person Info</a-doption>
              <a-doption @click="this.jumpWebPage('https://docs.a4x.io',true)">Document</a-doption>
              <a-doption @click="logout">Logout</a-doption>
            </template>
          </a-dropdown>
        </div>
      </div>
    </a-layout-header>
    <a-layout class="layout-demo">
      <slot name="leftSider"></slot>
      <a-layout-content class="content-layout">
        <div class="my-content">
          <slot name="content"></slot>
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import { IconUser } from "@arco-design/web-vue/es/icon";

export default {
  components: {
    IconUser
  },
  data() {
    return {};
  },
  methods: {
    async logout() {
      await this.$store.dispatch("user/logout");
      this.$router.push({ path: "/login" });
    }
  }
};
</script>

<style lang="scss" scoped>

.main-layout {
  height: 100%;
  background: #F2F3F5;
}

.my-header {
  width: 100%;
  height: 58px;
  box-sizing: content-box;
  padding-bottom: 16px;
  background: #F2F3F5;
}

.my-header-content {
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  box-shadow: 0px -1px 0px 0px #E5E6E8;

  //padding-left: 20px;
}

.layout-demo {
  background: #F2F3F5;
}

.content-layout {
  width: 100%;
  height: 100%;
  padding: 0 16px 16px 16px;
  background: #F2F3F5;
}

.my-content {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background: white;

  overflow: auto;
}

</style>
